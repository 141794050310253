import React from 'react';
import { Link } from 'gatsby';

import ArrowLink from '../assets/images/breath-arrow-link.svg';
import SEO from '../components/Seo';

import '../assets/styles/privacy.scss';

const PrivacyPage = () => (
  <>
    <SEO
      title="THE BREATH METHOD | Privacy Policies"
      description="Top rated breathing App for breath optimization. Get daily guided practices to improve your breathing technique, breath hacks to manage your mood, and education from leaders in the field."
    />
    <main className="main-content">
      <div className="container">
        <div className="main-content__inner">
          <div className="main-content__link-wrap">
            <Link to="/" className="main-content__link">
              <ArrowLink className="main-content__link-svg" />
              Back
            </Link>
          </div>
          <section className="privacy">
            <header className="privacy__header">
              <h2 className="privacy__title">Privacy Policies & Terms</h2>
              <p className="privacy__header-text">
                At Yet, we take your privacy seriously. Please read it.
              </p>
            </header>
            <div className="privacy__inner">
              <h3 className="privacy__subtitle">Terms of service</h3>
              <div className="privacy__date-wrap">
                Last Updated: <time className="privacy__date">May 2, 2018</time>
              </div>
              <p className="privacy__text">
                These Terms of Service (the “Terms”) describe the terms and conditions for
                your use of our websites and services and all the other products, services
                and applications made available by Yet Analytics, Inc. (the “Services”).
                You must agree and accept all of the Terms, or you don’t have the right to
                use the Services. Your using the Services in any way means that you agree
                to all of these Terms, and these Terms will remain in effect while you use
                the Services. We reserve the right, at our sole discretion, to change,
                modify or otherwise alter these Terms at any time. If you use the Services
                in any way after a change to the Terms is effective, then please remember
                that means you agree to all of the Terms. Except for changes by us as
                described here, no other amendment or modification of these Terms will be
                effective unless in writing and signed by both you and us.
              </p>
              <h3 className="privacy__subtitle">Terms of service</h3>
              <p className="privacy__text">
                We may use your personal data for the following purposes:
              </p>
              <ul className="privacy__list">
                <li className="privacy__item">
                  • Creating an account. Fulfilling your transaction or service requests,
                  including fulfilling orders; delivering, activating, or verifying
                  products or services; providing training and certification; managing and
                  processing training and certification exams; participating in onsite or
                  virtual activities; fulfilling your requests for changes or providing
                  you with the requested information (such as marketing materials for
                  products and services, and white papers); and providing technical
                  support.
                </li>
                <li className="privacy__item">
                  • Contacting you with your consent; sending you information about
                  products and services that may interest you; inviting you to participate
                  in Huawei activities (including promotional activities), market surveys,
                  or satisfaction surveys; or sending you marketing information. If you do
                  not want to receive these types of information, you can opt out at any
                  time.
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </main>
  </>
);

export default PrivacyPage;
